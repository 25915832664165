<template>
  <div class="activityDialog">
    <el-dialog top="3%" width="50%" :title="dialogTitle" :visible.sync="visible" :close-on-click-modal="false"
      :before-close="closeEvent">
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="活动名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入活动名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品单价" prop="price">
              <el-input type="number" v-model="form.price" placeholder="请输入商品单价">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品原价" prop="oldPrice">
              <el-input type="number" v-model="form.oldPrice" placeholder="请输入商品单价">

                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品总数量" prop="allNum">
              <el-input type="number" v-model="form.allNum" placeholder="请输入商品总数量"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="佣金比例(%)" prop="yj">
              <el-input type="number" v-model="form.yj" placeholder="请输入佣金比例(%)">

                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="快递费" prop="yj">
              <el-input type="number" v-model="form.kdf" placeholder="快递费">

                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="活动状态" prop="status">
              <el-select v-model="form.status" placeholder="请选择活动状态" style="width: 100%">
                <el-option :label="item.label" :value="item.value" v-for="item in statusList"
                  :key="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序" prop="sort">
              <el-input type="number" v-model="form.sort" placeholder="排序">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类" prop="status">
              <el-select v-model="form.classifyId" placeholder="请选择分类" style="width: 100%">
                <el-option :label="item.name" :value="item.id" v-for="item in classifyList" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="活动封面" prop="name">
              <el-upload class="avatar-uploader" :headers="headers" :action="'/api/upload?group=active'"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="form.coverImg" :src="form.coverImg" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="活动简介" prop="tips">
              <el-input type="textarea" :rows="2" placeholder="请输入活动简介" v-model="form.tips">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="活动内容" prop="content">
              <vue-editor useCustomImageHandler @image-added="handleImageAdded" v-model="form.content"></vue-editor>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import config from "@/assets/api/config.js";
export default {
  components: { VueEditor },
  data() {
    return {
      visible: false,
      dialogTitle: "新增活动",
      statusList: [
        {
          label: "未开始",
          value: "0",
        },
        {
          label: "进行中",
          value: "1",
        },
        {
          label: "已结束",
          value: "2",
        },
      ],
      classifyList: [],//分类列表
      form: {
        name: "",
        coverImg: "",
        beginTime: "",
        endTime: "",
        status: "",
        tips: "",
        content: "",
        price: "",
        oldPrice: "",
        allNum: "",
        yj: "",
        kdf: 0, //快递费
        sort: '',//排序
        classifyId: '',//分类
      },
      headers: {
        token: localStorage.getItem("token"),
      },
      config,
    };
  },
  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);

      axios({
        url: "/api/upload?group=activeDetails",
        method: "POST",
        data: formData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((result) => {
          const url = result.data.data.path; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.form.coverImg = res.data.path;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    submitData() {
      let params = JSON.parse(JSON.stringify(this.form));
      params.price = parseFloat(params.price) * 100;
      params.oldPrice = parseFloat(params.oldPrice) * 100;
      params.kdf = parseFloat(params.kdf) * 100;
      let url = "";
      if (params.id) {
        //修改
        url = "api/activity/update";
      } else {
        url = "api/activity/add";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增活动";
      this.form = {};
    },
    getClassifyList() {
      //获取分类列表
      this.getAjax('api/classify/list', {
        page: 1,
        pageSize: 100
      }, 'get').then(res => {
        this.classifyList = res.data.list.records;
      })
    },
    getInfo(id) {
      this.getClassifyList();
      this.dialogTitle = "编辑活动";
      this.getAjax("api/activity/details?id=" + id, {}, "get").then((res) => {
        res.data.info.price = parseFloat(res.data.info.price) / 100;
        res.data.info.oldPrice = parseFloat(res.data.info.oldPrice) / 100;
        res.data.info.kdf = parseFloat(res.data.info.kdf) / 100;
        this.form = res.data.info;
        this.visible = true;
      });
    },
  },
  mounted() {

  },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
