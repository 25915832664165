<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div style="margin-bottom: 10px">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />新增
      </el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }"
        border style="width: 100%">
        <el-table-column type="index" :index="indexMethod" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="name" label="活动名称" textAlign="left"></el-table-column>
        <el-table-column prop="coverImg" label="图片" width="180px">
          <template slot-scope="scope">
            <img :src="scope.row.coverImg" v-if="scope.row.coverImg" style="width: 100px; height: auto" />
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="分类" width="120px">

          <template slot-scope="scope">
            {{ getClassify(scope.row.classifyId) }}
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" width="120px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="110px">

          <template slot-scope="scope">{{
      scope.row.createTime | formatTime("YMDHMS")
    }}</template>
        </el-table-column>
        <el-table-column prop="price" label="商品价格" width="180px">

          <template slot-scope="scope">
            <div class="c_red">价格：{{ scope.row.price }}元</div>
            <div style="color: #999">原价：{{ scope.row.oldPrice }}元</div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="规格" width="180px">

          <template slot-scope="scope">
            <div v-for="item in scope.row.specsList" :key="item.id">{{ item.name }}：{{ item.price }}元</div>
          </template>
        </el-table-column>
        <el-table-column prop="yj" label="佣金比例(%)" width="80px"></el-table-column>
        <el-table-column prop="kdf" label="快递费（元）" width="80px"></el-table-column>
        <el-table-column prop="status" label="活动状态" width="120px">

          <template slot-scope="scope">{{
      scope.row.status == "0"
        ? "未开始"
        : scope.row.status == "1"
          ? "进行中"
          : "已结束"
    }}</template>
        </el-table-column>
        <el-table-column prop="enable" label="是否启用" width="120px">

          <template slot-scope="scope">
            <div>
              <span class="c_green" v-if="scope.row.enable == '1'">已启用</span>
              <span class="c_red" v-else>未启用</span>
            </div>
            <div>
              <span class="c_green" v-if="scope.row.recommand == '1'">已推荐</span>
              <span class="c_red" v-else>未推荐</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop label="操作" width="150">

          <template slot-scope="scope">
            <el-button type="text" size="small" @click="changeEnable(scope.row.enable, scope.row.id)">{{
      scope.row.enable == "1" ? "下架" : "上架" }}</el-button>
            <el-button type="text" size="small" @click="changeRecommand(scope.row.recommand, scope.row.id)">{{
      scope.row.recommand == "1" ? "取消推荐" : "设置推荐" }}</el-button>
            <el-button type="text" size="small" @click="editData(scope.row)">编辑</el-button>
            <el-button @click="deleteData(scope.row)" type="text" size="small">删除</el-button>
            <el-button @click="openSpecs(scope.row)" type="text" size="small">规格管理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <ActivityDialog ref="dialog" @search="initPage" />
    <SpecsDialog ref="specsDialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import ActivityDialog from "./components/dialog.vue";
import SpecsDialog from "./components/specsDialog.vue";
import config from "@/assets/api/config.js";
export default {
  components: { searchBar, ActivityDialog, SpecsDialog },
  data() {
    return {
      config,
      page: {
        page: 1,
        pageSize: 10,
      },
      params: {},
      total: 0,
      tableData: [],
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "name",
          attrs: {
            placeholder: "活动名称",
          },
        },
      ],
      classifyList: [],//分类列表
    };
  },
  methods: {
    getClassify(classifyId) {
      //获取分类名称
      if (!classifyId) return '';
      const obj = this.classifyList.find(e => e.id === classifyId);
      return obj.name || '';
    },
    openSpecs(item) {
      //打开添加规格弹出框
      this.$refs.specsDialog.visible = true;
      this.$refs.specsDialog.spId = item.id;
      this.$refs.specsDialog.getInfo(item.id);
    },
    changeEnable(enable, id) {
      const data = enable === "1" ? "0" : "1";
      this.getAjax("api/activity/enable", { enable: data, id }, "post").then(
        (res) => {
          this.initPage();
          this.message("操作成功");
        }
      );
    },
    changeRecommand(recommand, id) {
      const data = recommand === "1" ? "0" : "1";
      this.getAjax("api/activity/recommand", { recommand: data, id }, "post").then(
        (res) => {
          this.initPage();
          this.message("操作成功");
        }
      );
    },
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/activity/list",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        res.data.list.records.forEach((e) => {
          e.price = parseFloat(e.price) / 100;
          if (e.oldPrice) {
            e.oldPrice = parseFloat(e.oldPrice) / 100;
          }
          if (e.kdf) {
            e.kdf = parseFloat(e.kdf) / 100;
          }
        });
        this.tableData = res.data.list.records;
        this.total = res.data.list.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
      this.$refs.dialog.getClassifyList();
    },
    editData(item) {
      this.$refs.dialog.getInfo(item.id);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax("api/activity/del", { id: item.id }, "post").then(
          (res) => {
            this.$message.success("删除成功");
            this.initPage();
          }
        );
      });
    },
  },
  mounted() {
    this.initPage();
    //获取分类列表
    this.getAjax('api/classify/list', {
      page: 1,
      pageSize: 100
    }, 'get').then(res => {
      this.classifyList = res.data.list.records;
    })
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;

  .leftBox {
    width: 10%;
    min-width: 130px;
    height: 100%;
    background: #545c64;
  }
}
</style>
