<template>
  <div class="activityDialog">
    <el-dialog top="3%" width="50%" :title="dialogTitle" :visible.sync="visible" :close-on-click-modal="false"
      :before-close="closeEvent">
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row v-for="(item, index) in specsList" :key="index">
          <el-col :span="12">
            <el-form-item label="规格名称" prop="name">
              <el-input v-model="item.name" placeholder="请输入规格名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="商品单价" prop="price">
              <el-input type="number" v-model="item.price" placeholder="请输入商品单价">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <i class="el-icon-circle-plus" v-if="index === 0" @click="addLine()"
              style="font-size:30px;color:#409EFF;margin-top:5px;cursor:pointer;margin-left:20px;"></i>
            <i class="el-icon-remove" v-else @click="removeLine(index)"
              style="font-size:30px;color:#f00;margin-top:5px;cursor:pointer;margin-left:20px;"></i>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "规格管理",
      specsList: [
        {
          name: '规格一',
          price: ''
        }
      ],//规格列表
      form: {},
      spId: '',//当前商品ID
    };
  },
  methods: {
    addLine() {
      //新增一行
      this.specsList.push({
        name: '',
        price: ''
      })
    },
    removeLine(index) {
      //删除当前行
      this.specsList.splice(index, 1);
    },
    submitData() {
      let params = {
        spId: this.spId,
        specsList: this.specsList
      };
      this.getAjax('api/specs/add', params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.form = {};
    },
    getInfo(id) {
      //查询当前商品的规格
      this.getAjax('api/specs/findSpecsInfo', { id }, "post").then((res) => {
        if (res.data && res.data.length !== 0) {
          this.specsList = res.data;
        } else {
          this.specsList = [
            {
              name: '规格一',
              price: ''
            }
          ];
        }
      });
    },
  },
  mounted() {

  },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
